<template>
  <div>Select an item</div>
</template>

<script>
  export default {
    name: "idle",
  }
</script>

<style scoped>

</style>
