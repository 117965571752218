<template>
  <div>
    <div v-if="$route.params.categoryId" class="c-tabs">
      <div class="c-tab__tab-bar">
        <router-link v-for="ranking in rankings" :key="ranking.id"
                     class="c-button c-button--large c-button--ghost c-tabs__tab-link"
                     :to="{ name: 'cis.ranking',  params: { rankingId: ranking.id }}">
          <a>{{rankingName(ranking)}}</a>
        </router-link>
      </div>

      <div class="c-tabs__panel u-margin-top-medium">
        <router-view> </router-view>
      </div>
    </div>
  </div>
</template>

<script>
  import filter from 'lodash/filter'
  import find from 'lodash/find'

  import rankingLib from 'client/lib/ranking'
  import rounds from 'client/lib/rounds'

  export default {
    name: 'sessionCategory',
    computed: {
      category: function() {
        return find(this.$store.state.categories.items, i => i.id === this.$route.params.categoryId)
      },
      round: function() {
        const session = find(this.$store.state.sessions.items, s => s.id === this.$route.params.sessionId)
        return rounds.getRound(session.roundIndex)
      },
      rankings: function() {
        return rankingLib.sortRankings(filter(this.$store.state.rankings.items, i =>
          i.categoryId === this.$route.params.categoryId && i.roundIndex === this.round.index
        ))
      },
    },
    created: function() {
      if (! this.$route.params.rankingId && this.rankings.length) {
        this.$navigation.goto({ name: 'cis.ranking',  params: { rankingId: this.rankings[0].id }})
      }
    },
    methods: {
      rankingName: function(ranking) {
        return rankingLib.getTitle(ranking, this.category, this.round);
      }
    },
  }
</script>

<style scoped>

</style>
