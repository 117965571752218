<template>
  <div class="o-wrapper o-wrapper--huge c-wrapper">
    <vue-progress-bar></vue-progress-bar>
    <div class="c-wrapper--min-100vh">
      <header class="c-header o-wrapper o-wrapper--huge js-header" v-if="showHeader">
        <router-link :to="{ name: 'cis.sessions'}" class="c-logo__link">
          <div class="c-logo"></div>
        </router-link>
        <router-link exact
                     :to="{ name: 'cis.sessions'}"
                     class="c-header__title u-push-1-of-5 u-3-of-4">
          {{event.title}}
        </router-link>
        <breadcrump/>
      </header>

      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  export default {
    name:"cis-wrapper",
    components: {
      breadcrump: () => import('./breadcrump.vue'),
    },
    computed: {
      event: function () {
        return this.$store.state.events.current
      },
      showHeader: function() {
        return ! this.$route.matched.some(m => m.meta.hideHeader)
      },
    },
  };
</script>

<style scoped>
</style>
