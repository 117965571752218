<template>
  <div class="c-main-flex-1 o-layout o-layout--main">
    <div class="o-layout__item">
      <h2 class="c-title c-title--page">
        {{ session.name }}
      </h2>
    </div>
    <div class="o-layout__item c-cis-panels">
      <sessionPanel  v-for="panel in panels" :key="panel.id"
                     :panel="panel" class="c-cis-panels__item"/>
    </div>
  </div>
</template>

<script>
import sortBy from "lodash/sortBy";
import filter from "lodash/filter";
import find from "lodash/find";

export default {
  name: 'cisSessionPanels',
  components: {
    sessionPanel: () => import('./sessionPanel.vue'),
  },
  computed: {
    session: function() {
      return find(this.$store.state.sessions.items, (item) => item.id === this.$route.params.sessionId)
    },
    panels: function() {
      return sortBy(filter(this.$store.state.panels.items, item => {
        return item.sessionId === this.$route.params.sessionId;
      }), ['set', 'index']);
    },
  },
}
</script>

<style scoped>
.c-cis-panels {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  max-height: 95vh
}

.c-cis-panels__item {
  align-items: flex-start;
}

</style>
